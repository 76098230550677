import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Lottie from 'react-lottie';
import '../../App.css'; // Stílusok importálása
import drawAnimation from '../../animations/draw.json'; // Lottie animáció importálása

const TombolaDraw = () => {
  const [coupons, setCoupons] = useState([]);
  const [winner, setWinner] = useState(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [displayCoupon, setDisplayCoupon] = useState("");
  const [animationId, setAnimationId] = useState(null);

  useEffect(() => {
    // Tombolák letöltése URL-ről
    axios.get('https://leltar.slimstore.hu/filter_contest.php')
      .then(response => {
        setCoupons(response.data.contests);
      })
      .catch(error => console.error(error));
  }, []);

  // Sorsolás állapot kezelésére szolgáló useEffect
  useEffect(() => {
    if (isDrawing) {
      // Csak akkor állítjuk be az intervallumot, ha a sorsolás folyamatban van
      const id = setInterval(() => {
        const randomIndex = Math.floor(Math.random() * coupons.length);
        setDisplayCoupon(coupons[randomIndex]?.tombolacoupon || "");
      }, 5); // 50 ms-enként frissíti a kijelzett tombolát
      setAnimationId(id);
    }
    return () => {
      if (animationId) {
        clearInterval(animationId); // Intervallum törlése a cleanup function-ben
        setAnimationId(null); // Töröljük az animationId-t, hogy elkerüljük a felesleges frissítéseket
      }
    };
  }, [isDrawing, coupons]); // Csak isDrawing és coupons változására fut le

  const handleKeyPress = useCallback((event) => {
    if (event.code === 'Space' && isDrawing) {
      // Space megnyomása esetén a sorsolás leállítása
      setIsDrawing(false);
      setTimeout(() => {
        const randomIndex = Math.floor(Math.random() * coupons.length);
        const selectedWinner = coupons[randomIndex];
        setWinner(selectedWinner);
        axios.post('https://leltar.slimstore.hu/filter_contest.php?ticket_number=' + selectedWinner['tombolacoupon'])
      }, 0); // Kis várakozás a megállítás után
    } else {
      // Ha nem sorsolás folyamatban, akkor a Space billentyűvel indítható
      if (event.code === 'Space' && !isDrawing) {
        startDraw();}
    }
  }, [coupons, isDrawing]);

  useEffect(() => {
    // Space billentyű figyelése
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  const startDraw = () => {
    if (coupons.length === 0) {
      alert("Nincsenek tombolák betöltve!");
      return;
    }
    setWinner(null);
    setIsDrawing(true);
  };

  // Lottie animáció beállításai
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: drawAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div style={{ textAlign: 'center', padding: '50px',height: "100%"}}>
      {!winner && ( 
        <div className="display" style={{ margin: '20px 0' }}>
          {isDrawing ? (
            <div>
              <h2 style={{ color: '#ff4757', marginTop: '20px' }}>iPhone 16</h2>
              <p style={{ fontSize: '4.2rem' }}><strong>{displayCoupon || "Sorsolás..."}</strong></p>
            </div>
          ) : (
              <h2 style={{ color: '#ff4757', marginTop: '20px' }}>iPhone 16</h2>
          )}
        </div>
      )}
      {/* {!winner && ( 
        <button
          className="draw-button"
          onClick={startDraw}
          style={{
            background: '#ff4757',
            color: '#fff',
            borderRadius: '50px',
            padding: '15px 50px',
            fontSize: '1.5rem',
            cursor: 'pointer',
            marginTop: '20px',
            boxShadow: '0 20px 40px rgba(0,0,0,0.3)',
            border: 'none',
            transition: 'background 0.3s ease'
          }}
          onMouseOver={(e) => (e.currentTarget.style.background = '#ff6b6b')}
          onMouseOut={(e) => (e.currentTarget.style.background = '#ff4757')}
        >
          {isDrawing ? "Sorsolás folyamatban..." : "Sorsolás indítása"}
        </button>
      )} */}
      {winner && (
        <div className="winner" style={{ marginTop: '30px' }}>
          <Lottie options={defaultOptions} height={250} width={300} />
          <h2 style={{ color: '#ff4757', marginTop: '20px' }}>Nyertes Tombola</h2>
          <p style={{ fontSize: '4.2rem' }}><strong>{winner.tombolacoupon}</strong></p>
          <img src="https://cdsassets.apple.com/live/7WUAS350/images/tech-specs/iphone-16.png"></img>
        </div>
      )}
      {!winner && (
        <div className="winner" style={{ marginTop: '30px' }}>
          <img src="https://cdsassets.apple.com/live/7WUAS350/images/tech-specs/iphone-16.png"></img>
        </div>
      )}
      
    </div>
  );
};

export default TombolaDraw;