import { NetworkManager } from '../NetworkManager';
import { apiUrl } from '../../Components/App/Constants';

const apiManager = new NetworkManager(apiUrl);

//TIMESHEET KEZDETE//
// API lekérdezés a timesheethez
export async function getTimeSheet(startDate) {
  try {
    const response = await apiManager.get('/timesheet/?yearmonth=' + startDate);
    if (response.status === 200) {
        return response.data;
    } else {
      console.error('Unexpected response:', response);
    }
  } catch (error) {
    console.error('Hiba felvitel hiba:', error);
  }
}


//API POST a timesheethez
export async function postTimeSheet(json, onSuccess) {
    try {
      const response = await apiManager.post('/timesheet/', json);
      if (response.status === 201) {
          onSuccess(response.data);
      } else {
        console.error('Unexpected response:', response);
      }
    } catch (error) {
      console.error('Hiba felvitel hiba:', error);
    }
  }

  //API POST a timesheethez
export async function postFreeDays(json) {
  try {
    const response = await apiManager.post('/timesheet/', json);
    if (response.status === 201) {
        return response.data;
    } else {
      console.error('Unexpected response:', response);
    }
  } catch (error) {
    console.error('Hiba felvitel hiba:', error);
  }
}
//TIMESHEET VÉGE//