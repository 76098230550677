import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Lottie from 'react-lottie';
import { Scanner } from '@yudiel/react-qr-scanner';
import '../../App.css'; // Stílusok importálása
import drawAnimation from '../../animations/draw.json'; // Lottie animáció importálása

const CinemaTicket = () => {
  const [coupons, setCoupons] = useState([]);
  const [qrOpen, setQrOpen] = useState(false);
  const [qrPurpose, setQrPurpose] = useState("");
  const [ticketData, setTicketData] = useState("");
  const [slimmerData, setSlimmerData] = useState("");
  const ticketDataRef = useRef(null);
  useEffect(() => {
    // Tombolák letöltése URL-ről
  }, []);

const handleQrScan = (data) => {
  if (data) {
    try {
      // QR kód dekódolása
      var decodedData = atob(data[0].rawValue);
      decodedData = JSON.parse(decodedData);

      if (qrPurpose === "megvasarolt") {
        setQrOpen(false);
        axios.get('https://leltar.slimstore.hu/getticket.php?id=' + decodedData["id"])
          .then(response => {
            if (response.data && response.data[0].is_validated === "0") {
              alert("Jegy érvényes és átvehető! " + decodedData["id"] + ".szorszámú jegy");
              axios.patch('https://leltar.slimstore.hu/validate_ticket.php?id=' + decodedData["id"])
                .then(response => console.log(response))
                .catch(error => console.error(error));
            } else {
              alert("Hibás QR kód vagy már érvényesítve lett a jegy!");
            }
          })
          .catch(error => console.error(error));
      } else if (qrPurpose === "ingyenes") {
        if (decodedData["Email"] == null && !ticketData) {
          setTicketData(decodedData["id"]);
          ticketDataRef.current = decodedData["id"]; // Ref frissítése
          alert("Jegy QR kód beolvasva, most olvassa le a Slimmer QR kódot!");
        } else if (!slimmerData && decodedData["Email"] != null) {
          // Második QR-kód olvasás (Slimmer)
          if (!ticketDataRef.current) {
            alert("Hiba: Jegy QR kód nincs megfelelően beállítva!");
            return;
          }

          setSlimmerData(decodedData);
          setQrOpen(false);

          // Itt küldjük el az adatokat a szervernek
          axios.post(
            `https://leltar.slimstore.hu/assignticket.php?id=${ticketDataRef.current}&email=${decodedData["Email"]}&name=${encodeURIComponent(decodedData["Name"])}`
          )
            .then(response => 
            {
                if(response.data.message === 
                    "Sikeresen hozzárendelve és tombola létrehozva!") {
                    alert("Jegy sikeresen hozzárendelve és tombola létrehozva! " + ticketDataRef.current + ".szorszámú jegy");
                } else {
                    alert("Hiba: " + response.data.message);
                }

                ticketDataRef.current = null;
                setTicketData("");
                setSlimmerData("");
            }
            )
            .catch(error => console.error(error));
        } else {
          alert("Hibás QR kód!");
        }
      }
    } catch (error) {
      console.error("QR kód feldolgozási hiba: ", error);
      alert("Érvénytelen QR kód!");
    }
  }
};

  const openQrScanner = (purpose) => {
    setQrPurpose(purpose);
    setQrOpen(true);
  };

  const closeQrScanner = () => {
    setQrOpen(false);
    setQrPurpose("");
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: drawAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div style={{ textAlign: 'center', padding: '50px', height: "100%" }}>
      <div style={{ marginBottom: '20px' }}>
        <button
          style={{
            background: '#4caf50',
            color: '#fff',
            padding: '10px 20px',
            borderRadius: '5px',
            margin: '10px',
            fontSize: '1rem',
            cursor: 'pointer',
          }}
          onClick={() => openQrScanner("megvasarolt")}
        >
          Megvásárolt jegy átvétele
        </button>
        <button
          style={{
            background: '#ff9800',
            color: '#fff',
            padding: '10px 20px',
            borderRadius: '5px',
            margin: '10px',
            fontSize: '1rem',
            cursor: 'pointer',
          }}
          onClick={() => openQrScanner("ingyenes")}
        >
          Ingyenes jegy átadás
        </button>
      </div>

      {qrOpen && (
        <div style={{
          position: 'fixed',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          zIndex: '1000',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <div style={{
            background: '#fff',
            padding: '20px',
            borderRadius: '10px',
            textAlign: 'center',
            position: 'relative',
          }}>
            <h2>{qrPurpose === "megvasarolt" ? "Megvásárolt jegy átvétele" : ticketData === "" ? "Jegy beolvasása" : "Slimmer kód beolvasása"}</h2>
            <Scanner
              onScan={handleQrScan}
              constraints={{ facingMode: 'environment' }}
              style={{ width: '100%', height: '300px' }} // Mobilon jobb méretarány
            />
            <button
              style={{
                background: '#f44336',
                color: '#fff',
                padding: '10px 20px',
                borderRadius: '5px',
                marginTop: '20px',
                cursor: 'pointer',
              }}
              onClick={closeQrScanner}
            >
              Bezárás
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CinemaTicket;