import React, {useContext} from 'react';
import './Statisztika.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard, faMoneyBill1Wave, faHandSparkles, faMobile, faMobileScreen, faBattery, faHammer, faTabletScreenButton, faPersonDress, faIdCard } from '@fortawesome/free-solid-svg-icons'
import jwt_decode from "jwt-decode";
import SubStat from "./SubStat/SubStat"
import Popup from 'reactjs-popup';
import { ThemeContext } from '../App/ThemeContext';
import 'reactjs-popup/dist/index.css';

export default function Statisztika({ vouchers, parentToChild, scraps, startDate, warranty, cardVoucheredItems, endDate }) {
  const { theme } = useContext(ThemeContext);
  const primaryColor = theme.primary;
  const getCountOf = itemName => {
    var count = 0
    parentToChild.forEach(element => {
      count = count + element.items.filter(item => item.itemType === itemName).length
    });
    return count
    
  }
  const formatResult = (result) => {
    // Ha az eredmény .00-ra végződik, akkor 0 tizedes jegy, különben 2 tizedesjegy
    return result % 1 === 0 ? result.toFixed(0) : result.toFixed(1);
  };
  let cutoffDate = new Date("2023-10-10");
  let cutoffDateDec1 = new Date("2023-12-01");
  let cutoffDateJun = new Date("2024-06-01");
  let cutoffDateNov = new Date("2024-11-18");
  var division;
  if (new Date(startDate) >= cutoffDateNov) {
    division = 14000;
  } else if (new Date(startDate) >= cutoffDateJun) {
    division = 12000;
  } else if (new Date(startDate) >= cutoffDateDec1) {
    division = 10000;
  } else if (new Date(startDate) >= cutoffDate) {
    division = 8000;
  } else {
    division = 7000;
  }
  const getCountOfScrap = (itemName, scrapType) => {
      return scraps.filter(item => item.type === itemName).filter(item1 => item1.scraporreplace === scrapType).length
  }
  const getCleanCard = (itemPrice) => {
    var count = 0;
    parentToChild.forEach(element => {
      count = count + element.items.filter(item => item.itemType === "Tisztítás kártya" && item.itemPrice == itemPrice).length
  });
    return count;
  }
  const getUsedCleanCard = (itemPrice) => {
    let count = 0;
    vouchers.forEach(element => {
      try {
        // Decode Base64 to Uint8Array
        const decodedBytes = Uint8Array.from(atob(element.qrstring), c => c.charCodeAt(0));
        const decodedString = new TextDecoder("utf-8").decode(decodedBytes);
        const decodedData = JSON.parse(decodedString);
        console.log(decodedData);
        if (
          decodedData.cardType === "Tisztítás kártya" &&
          parseInt(decodedData.price) === parseInt(itemPrice)
        ) {
          count++;
        }
      } catch (error) {
        console.error("Hibás QR string, nem lehet dekódolni.", error);
      }
    });
    return count;
  };
  const getEveryPromoter = () => {
    const uniqueTags = [];
    parentToChild.map(img => {
    if (uniqueTags.indexOf(img.userName) === -1) {
      uniqueTags.push(img.userName)
      }
    })
    return uniqueTags
  }

  return(
    <div id='statisztika'>
      <a href='#leltar' className='gotoBlockLink' style={{ color: primaryColor, borderColor: primaryColor}}>Leltár</a>
      <h2 style={{ color: primaryColor}}>Statisztika</h2>
      {getEveryPromoter().map(item1 => {
          var decodedUser = jwt_decode(localStorage.getItem("accessToken"));
          if (decodedUser.priv === 1 || decodedUser.priv === 0) {
var divisior = 7000;
let cutoffDate = new Date("2023-10-10");
let cutoffDateDec1 = new Date("2023-12-01");
let cutOffDateJun = new Date("2024-06-01");
var result = parentToChild.filter(item => {
    let itemDate = new Date(item.time);
    if (itemDate >= cutoffDate && itemDate < cutoffDateDec1) {
      divisior = 8000;
  } else if(itemDate >= cutoffDateNov) {
    divisior = 14000;
  } else if (itemDate >= cutOffDateJun) {
    divisior = 12000;
  } else if (itemDate >= cutoffDateDec1) {
    divisior = 10000;
  }
    return item.userName === item1;
}).reduce((a, v) => a + parseInt(v.price), 0) / divisior;
var resultisLeader = parentToChild.filter(item => {
  let itemDate = new Date(item.time);
  if (itemDate >= cutoffDate && itemDate < cutoffDateDec1) {
    divisior = 8000;
} else if (itemDate >= cutoffDateNov) {
  divisior = 14000;
}else if (itemDate >= cutOffDateJun) {
  divisior = 12000;
} else if (itemDate >= cutoffDateDec1) {
  divisior = 10000;
}
  return item.userName === item1 && item.isLeader === "1";
}).reduce((a, v) => a + parseInt(v.price), 0) / divisior;
var resultisNotLeader = parentToChild.filter(item => {
  let itemDate = new Date(item.time);
  if (itemDate >= cutoffDate && itemDate < cutoffDateDec1) {
    divisior = 8000;
} else if (itemDate >= cutoffDateNov) {
  divisior = 14000;
}else if (itemDate >= cutOffDateJun) {
  divisior = 12000;
} else if (itemDate >= cutoffDateDec1) {
  divisior = 10000;
}
  return item.userName === item1 && item.isLeader === "0";
}).reduce((a, v) => a + parseInt(v.price), 0) / divisior;

            return (
              <div className='statUserRow' style={{ borderColor: primaryColor}}>
                
            <div className='paymentDiv'>
            <p style={{ color: "white", backgroundColor: primaryColor}} className='paymentQty resultlabel'>{formatResult(result)} db</p>
            <p className='productType pay' style={{ color: primaryColor, background: "white", fontWeight: "800", fontSize: "15px"}}>{item1}</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faPersonDress} />
            <p className='financialDiv' style={{backgroundColor: primaryColor, color: "white", border: "none"}}>{parentToChild.filter(item => item.userName === item1).length} vásárló</p>
            <p className='financialDiv' style={{backgroundColor: primaryColor, color: "white", border: "none"}}>{parentToChild.filter(item => item.userName === item1 && item.slimmerEmail != "\"\"" && item.slimmerEmail != null && item.slimmerEmail != "").length} Slimmer</p>
            
                  <p className='financialDiv'>{parentToChild.filter(item => item.userName === item1).reduce((a, v) => a = a + parseInt(v.price), 0).toLocaleString()} Ft</p>
                    
                  <Popup trigger={<button style={{border: "2px solid", borderColor: primaryColor, color: primaryColor, backgroundColor: "white", borderRadius: "10px", fontSize: "12px"}}>Részletes</button>} modal>

                  <SubStat statItems={parentToChild.filter(itemOk => itemOk.userName === item1)} typeOf="Slim UV" />
                  <SubStat statItems={parentToChild.filter(itemOk => itemOk.userName === item1)} typeOf="Garancia 50" />
                  <SubStat statItems={parentToChild.filter(itemOk => itemOk.userName === item1)} typeOf="Garancia 100" />
                  <SubStat statItems={parentToChild.filter(itemOk => itemOk.userName === item1)} typeOf="Slim Privacy Matt" />
                  <SubStat statItems={parentToChild.filter(itemOk => itemOk.userName === item1)} typeOf="Slim Self-healing" />
                  <SubStat statItems={parentToChild.filter(itemOk => itemOk.userName === item1)} typeOf="Slim HD" />
                    <SubStat statItems={parentToChild.filter(itemOk => itemOk.userName === item1)} typeOf="Slim Pad HD"/>
                    <SubStat statItems={parentToChild.filter(itemOk => itemOk.userName === item1)} typeOf="Slim Pad Matt"/>
                    <SubStat statItems={parentToChild.filter(itemOk => itemOk.userName === item1)} typeOf="Tok" />
                    <SubStat statItems={parentToChild.filter(itemOk => itemOk.userName === item1)} typeOf="Slim Power" />
                    <SubStat statItems={parentToChild.filter(itemOk => itemOk.userName === item1)} typeOf="Fekete kártya" />
                    <SubStat statItems={parentToChild.filter(itemOk => itemOk.userName === item1)} typeOf="Fehér kártya" />
                    <SubStat statItems={parentToChild.filter(itemOk => itemOk.userName === item1)} typeOf="Tisztítás kártya (6000 Ft)" />
                    <SubStat statItems={parentToChild.filter(itemOk => itemOk.userName === item1)} typeOf="Szerviz Ezüst 5% kártya" />
                    <SubStat statItems={parentToChild.filter(itemOk => itemOk.userName === item1)} typeOf="Szerviz Arany 10% kártya" />
                    <div className='nagyDiv'>
                      <h2 className='statTitle'>Csere</h2>
                    <div className='scrapDiv'>
                  <p className='paymentQty'>{scraps.filter(item => item.type === "Sima fólia").filter(item2 => item2.scraporreplace === "Csere").filter(itemOk => itemOk.userName === item1).length + scraps.filter(item => item.type === "Slim HD").filter(item2 => item2.scraporreplace === "Csere").filter(itemOk => itemOk.userName === item1).length} db</p>
                  <p className='productType pay'>Slim HD</p>
                  <br></br>
                  <FontAwesomeIcon className='famenu' icon={faMobileScreen} />
                    </div>
                    <div className='scrapDiv'>
                  <p className='paymentQty'>{scraps.filter(item => item.type === "Privacy fólia").filter(item2 => item2.scraporreplace === "Csere").filter(itemOk => itemOk.userName === item1).length + scraps.filter(item => item.type === "Slim Privacy Matt").filter(item2 => item2.scraporreplace === "Csere").filter(itemOk => itemOk.userName === item1).length} db</p>
                  <p className='productType pay'>Slim Privacy Matt</p>
                  <br></br>
                  <FontAwesomeIcon className='famenu' icon={faHandSparkles} />
                    </div>
                    <div className='scrapDiv'>
                  <p className='paymentQty'>{scraps.filter(item => item.type === "UV fólia").filter(item2 => item2.scraporreplace === "Csere").filter(itemOk => itemOk.userName === item1).length + scraps.filter(item => item.type === "Slim UV").filter(item2 => item2.scraporreplace === "Csere").filter(itemOk => itemOk.userName === item1).length} db</p>
                  <p className='productType pay'>Slim UV</p>
                  <br></br>
                  <FontAwesomeIcon className='famenu' icon={faHandSparkles} />
                    </div>
                      <div className='scrapDiv'>
                  <p className='paymentQty'>{scraps.filter(item => item.type === "iPad HD fólia").filter(item2 => item2.scraporreplace === "Csere").filter(itemOk => itemOk.userName === item1).length + scraps.filter(item => item.type === "Slim Pad HD").filter(item2 => item2.scraporreplace === "Csere").filter(itemOk => itemOk.userName === item1).length} db</p>
                  <p className='productType pay'>iPad HD fólia</p>
                  <br></br>
                  <FontAwesomeIcon className='famenu' icon={faTabletScreenButton} />
                    </div>
                    <div className='scrapDiv'>
                  <p className='paymentQty'>{scraps.filter(item => item.type === "iPad Privacy fólia").filter(item2 => item2.scraporreplace === "Csere").filter(itemOk => itemOk.userName === item1).length + scraps.filter(item => item.type === "Slim Pad Matt").filter(item2 => item2.scraporreplace === "Csere").filter(itemOk => itemOk.userName === item1).length} db</p>
                  <p className='productType pay'>Slim Pad Matt</p>
                  <br></br>
                  <FontAwesomeIcon className='famenu' icon={faTabletScreenButton} />
                    </div>
                    
                    </div>
                    
                    <div className='nagyDiv'>
                      <h2 className='statTitle'>Selejt</h2>
                    <div className='scrapDiv'>
                  <p className='paymentQty'>{scraps.filter(item => item.type === "Sima fólia").filter(item2 => item2.scraporreplace === "Selejt").filter(itemOk => itemOk.userName === item1).length + scraps.filter(item => item.type === "Slim HD").filter(item2 => item2.scraporreplace === "Selejt").filter(itemOk => itemOk.userName === item1).length} db</p>
                  <p className='productType pay'>Slim HD</p>
                  <br></br>
                  <FontAwesomeIcon className='famenu' icon={faMobileScreen} />
                    </div>
                    <div className='scrapDiv'>
                  <p className='paymentQty'>{scraps.filter(item => item.type === "Privacy fólia").filter(item2 => item2.scraporreplace === "Selejt").filter(itemOk => itemOk.userName === item1).length + scraps.filter(item => item.type === "Slim Privacy Matt").filter(item2 => item2.scraporreplace === "Selejt").filter(itemOk => itemOk.userName === item1).length} db</p>
                  <p className='productType pay'>Slim Privacy Matt</p>
                  <br></br>
                  <FontAwesomeIcon className='famenu' icon={faHandSparkles} />
                    </div>
                    <div className='scrapDiv'>
                  <p className='paymentQty'>{scraps.filter(item => item.type === "UV fólia").filter(item2 => item2.scraporreplace === "Selejt").filter(itemOk => itemOk.userName === item1).length + scraps.filter(item => item.type === "Slim UV").filter(item2 => item2.scraporreplace === "Selejt").filter(itemOk => itemOk.userName === item1).length} db</p>
                  <p className='productType pay'>Slim UV</p>
                  <br></br>
                  <FontAwesomeIcon className='famenu' icon={faHandSparkles} />
                    </div>
                       <div className='scrapDiv'>
                  <p className='paymentQty'>{scraps.filter(item => item.type === "iPad HD fólia").filter(item2 => item2.scraporreplace === "Selejt").filter(itemOk => itemOk.userName === item1).length + scraps.filter(item => item.type === "Slim Pad HD").filter(item2 => item2.scraporreplace === "Selejt").filter(itemOk => itemOk.userName === item1).length} db</p>
                  <p className='productType pay'>Slim Pad</p>
                  <br></br>
                  <FontAwesomeIcon className='famenu' icon={faTabletScreenButton} />
                    </div>
                    <div className='scrapDiv'>
                  <p className='paymentQty'>{scraps.filter(item => item.type === "iPad Privacy fólia").filter(item2 => item2.scraporreplace === "Selejt").filter(itemOk => itemOk.userName === item1).length + scraps.filter(item => item.type === "Slim Pad Matt").filter(item2 => item2.scraporreplace === "Selejt").filter(itemOk => itemOk.userName === item1).length} db</p>
                  <p className='productType pay'>Slim Pad Matt</p>
                  <br></br>
                  <FontAwesomeIcon className='famenu' icon={faTabletScreenButton} />
                    </div>
                    <br></br>
                    <h2 className='statTitle'>Kártyával eladott termékek</h2>
                    <SubStat statItems={cardVoucheredItems.filter(itemOk => itemOk.userName === item1)} typeOf="Slim HD" />
                    <SubStat statItems={cardVoucheredItems.filter(itemOk => itemOk.userName === item1)} typeOf="Slim Privacy Matt" />
                    <SubStat statItems={cardVoucheredItems.filter(itemOk => itemOk.userName === item1)} typeOf="Slim Self-healing" />
                    <SubStat statItems={cardVoucheredItems.filter(itemOk => itemOk.userName === item1)} typeOf="Slim UV" />
                    <SubStat statItems={cardVoucheredItems.filter(itemOk => itemOk.userName === item1)} typeOf="Slim Pad HD"/>
                    <SubStat statItems={cardVoucheredItems.filter(itemOk => itemOk.userName === item1)} typeOf="Slim Pad Matt"/>
                    <SubStat statItems={cardVoucheredItems.filter(itemOk => itemOk.userName === item1)} typeOf="Tok" />
                      </div>
                  </Popup>
                      </div>
                <div> 
                  
                <div className='paymentDiv paymentDiv1'>
            <p className='paymentQty'>{parentToChild.filter(item => item.userName === item1).filter(item => item.paymentType === 'Bankkártyás').length} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Bankkártyás</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faCreditCard} />
            <p className='financialDiv'>{parentToChild.filter(item => item.userName === item1).filter(item => item.paymentType === 'Bankkártyás').reduce((a, v) => a = a + parseInt(v.price), 0).toLocaleString()} Ft</p>
                </div>
                <div className='paymentDiv paymentDiv1'>
            <p className='paymentQty '>{parentToChild.filter(item => item.userName === item1).filter(item => item.paymentType === 'Készpénzes').length} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Készpénzes</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faMoneyBill1Wave} />
            <p className='financialDiv'>{parentToChild.filter(item => item.userName === item1).filter(item => item.paymentType === 'Készpénzes').reduce((a, v) => a = a + parseInt(v.price), 0).toLocaleString()} Ft</p>
                  </div>
                  </div>
                </div>
              
          )
          }
          
          })}
      <div className='productAll'>
        <div className='productRow' style={{ borderColor: primaryColor}}>
          <p style={{ color: primaryColor, fontSize: "12px"}}>Összesítő</p>
          
          <h3 style={{margin: "5px"}}>{formatResult((parentToChild.reduce((a, v) => a = a + parseInt(v.price), 0)/division))} db</h3>
          <p className='financialDiv' > {parentToChild.reduce((a, v) => a = a + parseInt(v.price), 0).toLocaleString()} Ft</p><br></br>
          
          
          <p className='productType' style={{ backgroundColor: primaryColor}}>{(cardVoucheredItems.reduce((a, v) => a = a + v.items.length, 0))} db kártyás beváltott termék</p>
          <br></br>
          <p className='productType' style={{ backgroundColor: primaryColor}}>{(parentToChild.length)} vásárló - {parentToChild.filter(item => item.slimmerEmail != "\"\"" && item.slimmerEmail != null && item.slimmerEmail != "").length} Slimmer</p>
          <br></br>
          <p className='productType' style={{ backgroundColor: primaryColor}}>{(parentToChild.reduce((a, v) => a = a + v.items.length, 0))} termék</p>
          <br></br>
          
        </div>
        <div className='productRow' style={{ borderColor: primaryColor}}>
        <p style={{ color: primaryColor, fontSize: "12px"}}>Garancia eladás</p>
        <SubStat className='productType' statItems={parentToChild} typeOf="Garancia 100" />
        <SubStat className='productType' statItems={parentToChild} typeOf="Garancia 50" />
          </div>
      <div className='statRow' style={{ borderColor: primaryColor}}>
          <div className='paymentDiv'>
            <p className='paymentQty' >{parentToChild.filter(item => item.paymentType === 'Bankkártyás').length} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Bankkártyás</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faCreditCard} />
            <p className='financialDiv'>{parentToChild.filter(item => item.paymentType === 'Bankkártyás').reduce((a, v) => a = a + parseInt(v.price), 0).toLocaleString()} Ft</p>
          </div>
          <div className='paymentDiv' style={{ borderColor: primaryColor}}>
            <p className='paymentQty'>{parentToChild.filter(item => item.paymentType === 'Készpénzes').length} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Készpénzes</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faMoneyBill1Wave} />
            <p className='financialDiv'>{parentToChild.filter(item => item.paymentType === 'Készpénzes').reduce((a, v) => a = a + parseInt(v.price), 0).toLocaleString()} Ft</p>
          </div>
        </div>
        <div className='statRow' style={{ borderColor: primaryColor}}>
          <div className='paymentDiv'>
            <p className='paymentQty'>{getCountOf("Sima fólia")+getCountOf("Slim HD")} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim HD</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faMobileScreen} />
          </div>
          <div className='paymentDiv'>
            <p className='paymentQty'>{getCountOf("Self-healing fólia")+getCountOf("Slim Self-healing")} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim Self-healing</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faMobileScreen} />
          </div>
          <div className='paymentDiv' style={{ borderColor: primaryColor}}>
            <p className='paymentQty'>{getCountOf("Privacy fólia")+getCountOf("Slim Privacy Matt")} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim Privacy Matt</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faHandSparkles} />
          </div>
          <div className='paymentDiv' style={{ borderColor: primaryColor}}>
            <p className='paymentQty'>{getCountOf("UV fólia") + getCountOf("Slim UV")} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim UV</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faHandSparkles} />
          </div>

          
        </div>
        
        <div className='statRow' style={{ borderColor: primaryColor}}>
        <div className='paymentDiv'>
            <p className='paymentQty'>{getCountOf("Tok") + getCountOf("Slim Case") + getCountOf("Slim Pro Case") + getCountOf("Slim Pro Magsafe Case")} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Tok</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faHammer} />
            <br></br>
            <Popup trigger={<button style={{border: "2px solid", borderColor: primaryColor, color: primaryColor, backgroundColor: "white", borderRadius: "10px", fontSize: "12px"}}>Részletes</button>} modal>
            <SubStat statItems={parentToChild} typeOf="Tok" />
            </Popup>
          </div>
          <div className='paymentDiv' >
            <p className='paymentQty'>{getCountOf("iPad Privacy fólia") + getCountOf("iPad HD fólia") + getCountOf("Slim Pad HD") + getCountOf("Slim Pad Matt")} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>iPad fóliák</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faTabletScreenButton} />
            <br></br>
            <Popup trigger={<button style={{border: "2px solid", borderColor: primaryColor, color: primaryColor, backgroundColor: "white", borderRadius: "10px", fontSize: "12px"}}>Részletes</button>} modal>
            <SubStat statItems={parentToChild} typeOf="iPad Privacy fólia" />
            <SubStat statItems={parentToChild} typeOf="iPad HD fólia" />
            </Popup>
          </div>
          <div className='paymentDiv' >
            <p className='paymentQty'>{getCountOf("Slim Power")} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim Power</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faBattery} />
          </div>
          <div className='paymentDiv'>
            <p className='paymentQty'>{getCountOf("UV fólia kártya") + getCountOf("Privacy fólia kártya") + getCountOf("Fólia kártya") + getCountOf("Garancia kártya") + getCountOf("Tisztítás kártya") + getCountOf("Fekete kártya") + getCountOf("Fehér kártya") + getCountOf("Szerviz Ezüst 5% kártya") + getCountOf("Szerviz Arany 10% kártya")} db</p>
            <p className='productType pay' style={{ backgroundColor: primaryColor}}>Kártyák</p>
            <br></br>
            <FontAwesomeIcon className='famenu' icon={faIdCard} />
            <br></br>
            <Popup trigger={<button style={{border: "2px solid", borderColor: primaryColor, color: primaryColor, backgroundColor: "white", borderRadius: "10px", fontSize: "12px"}}>Részletes</button>} modal>
            <SubStat statItems={parentToChild} typeOf="Fekete kártya" />
            <SubStat statItems={parentToChild} typeOf="Fehér kártya" />
            <SubStat statItems={parentToChild} typeOf="Tisztítás kártya (6000 Ft)" />
            <SubStat statItems={parentToChild} typeOf="Tisztítás kártya (12000 Ft)" />
            <SubStat statItems={parentToChild} typeOf="Szerviz Ezüst 5% kártya" />
            <SubStat statItems={parentToChild} typeOf="Szerviz Arany 10% kártya" />
            </Popup>
          </div>
        </div>
          <div>
            <div className='statRow' style={{ borderColor: primaryColor}}>
                <div className='paymentDiv'>
                  <p className='paymentQty'>{getCountOfScrap("Sima fólia", "Selejt") + getCountOfScrap("Slim HD", "Selejt")} db</p>
                  <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim HD</p>
                  <br></br>
                  <FontAwesomeIcon className='famenu' icon={faMobileScreen} />
                </div>
                <div className='paymentDiv'>
                  <p className='paymentQty'>{getCountOfScrap("Self-healing fólia", "Selejt") + getCountOfScrap("Slim Self-healing", "Selejt")} db</p>
                  <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim Self-healing</p>
                  <br></br>
                  <FontAwesomeIcon className='famenu' icon={faMobileScreen} />
                </div>
                <div className='paymentDiv'>
                  <p className='paymentQty'>{getCountOfScrap("Privacy fólia", "Selejt") + getCountOfScrap("Slim Privacy Matt", "Selejt")} db</p>
                  <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim Privacy Matt</p>
                  <br></br>
                  <FontAwesomeIcon className='famenu' icon={faHandSparkles} />
                </div>
                <div className='paymentDiv'>
                  <p className='paymentQty'>{getCountOfScrap("UV fólia", "Selejt") + getCountOfScrap("Slim UV", "Selejt")} db</p>
                  <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim UV</p>
                  <br></br>
                  <FontAwesomeIcon className='famenu' icon={faHandSparkles} />
                </div>
            <div className='paymentDiv'>
                  <p className='paymentQty'>{getCountOfScrap("iPad HD fólia", "Selejt") + getCountOfScrap("Slim Pad HD", "Selejt")} db</p>
                  <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim Pad</p>
                  <br></br>
                  <FontAwesomeIcon className='famenu' icon={faTabletScreenButton} />
            </div>
            <div className='paymentDiv'>
                  <p className='paymentQty'>{getCountOfScrap("iPad Privacy fólia", "Selejt") + getCountOfScrap("Slim Pad Matt", "Selejt")} db</p>
                  <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim Pad Matt</p>
                  <br></br>
                  <FontAwesomeIcon className='famenu' icon={faTabletScreenButton} />
            </div>
            
            </div>
            <div className='scrapLabel'><p>Selejt</p></div>
        </div>
        <div>
            <div className='statRow' style={{ borderColor: primaryColor}}>
                <div className='paymentDiv'>
                  <p className='paymentQty'>{getCountOfScrap("Sima fólia", "Csere") + getCountOfScrap("Slim HD", "Csere")} db</p>
                  <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim HD</p>
                  <br></br>
                  <FontAwesomeIcon className='famenu' icon={faMobileScreen} />
                </div>
                <div className='paymentDiv'>
                  <p className='paymentQty'>{getCountOfScrap("Self-healing fólia", "Csere") + getCountOfScrap("Slim Self-healing", "Csere")} db</p>
                  <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim Self-healing</p>
                  <br></br>
                  <FontAwesomeIcon className='famenu' icon={faMobileScreen} />
                </div>
                <div className='paymentDiv'>
                  <p className='paymentQty'>{getCountOfScrap("Privacy fólia", "Csere") + getCountOfScrap("Slim Privacy ", "Csere")} db</p>
                  <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim Privacy</p>
                  <br></br>
                  <FontAwesomeIcon className='famenu' icon={faHandSparkles} />
                </div>
                <div className='paymentDiv'>
                  <p className='paymentQty'>{getCountOfScrap("UV fólia", "Csere") +getCountOfScrap("Slim UV", "Csere")} db</p>
                  <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim UV</p>
                  <br></br>
                  <FontAwesomeIcon className='famenu' icon={faHandSparkles} />
                </div>
              
            <div className='paymentDiv'>
                  <p className='paymentQty'>{getCountOfScrap("iPad HD fólia", "Csere") + getCountOfScrap("Slim Pad HD", "Csere")} db</p>
                  <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim Pad</p>
                  <br></br>
                  <FontAwesomeIcon className='famenu' icon={faTabletScreenButton} />
            </div>
            <div className='paymentDiv'>
                  <p className='paymentQty'>{getCountOfScrap("iPad Privacy fólia", "Csere") + getCountOfScrap("Slim Pad Matt", "Csere")} db</p>
                  <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim Pad Matt</p>
                  <br></br>
                  <FontAwesomeIcon className='famenu' icon={faTabletScreenButton} />
            </div>
            
            </div>
            <div className='scrapLabel'><p>Csere</p></div>
          </div>
          <div>
            <div className='statRow' style={{ borderColor: primaryColor}}>
                <div className='paymentDiv'>
                  <p className='paymentQty'>{getCountOfScrap("Sima fólia", "Próba") + getCountOfScrap("Slim HD", "Próba")} db</p>
                  <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim HD</p>
                  <br></br>
                  <FontAwesomeIcon className='famenu' icon={faMobileScreen} />
                </div>
                <div className='paymentDiv'>
                  <p className='paymentQty'>{getCountOfScrap("Self-healing fólia", "Próba") + getCountOfScrap("Slim Self-healing", "Próba")} db</p>
                  <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim Self-healing</p>
                  <br></br>
                  <FontAwesomeIcon className='famenu' icon={faMobileScreen} />
                </div>
                <div className='paymentDiv'>
                  <p className='paymentQty'>{getCountOfScrap("Privacy fólia", "Próba") + getCountOfScrap("Slim Privacy Matt", "Próba")} db</p>
                  <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim Privacy Matt</p>
                  <br></br>
                  <FontAwesomeIcon className='famenu' icon={faHandSparkles} />
                </div>
                <div className='paymentDiv'>
                  <p className='paymentQty'>{getCountOfScrap("UV fólia", "Próba") + getCountOfScrap("Slim UV", "Próba")} db</p>
                  <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim UV</p>
                  <br></br>
                  <FontAwesomeIcon className='famenu' icon={faHandSparkles} />
                </div>
              
            <div className='paymentDiv'>
                  <p className='paymentQty'>{getCountOfScrap("iPad HD fólia", "Próba") + getCountOfScrap("Slim Pad HD", "Próba")} db</p>
                  <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim Pad</p>
                  <br></br>
                  <FontAwesomeIcon className='famenu' icon={faTabletScreenButton} />
            </div>
            <div className='paymentDiv'>
                  <p className='paymentQty'>{getCountOfScrap("iPad Privacy fólia", "Próba") + getCountOfScrap("Slim Pad Matt", "Próba")} db</p>
                  <p className='productType pay' style={{ backgroundColor: primaryColor}}>Slim Pad Matt</p>
                  <br></br>
                  <FontAwesomeIcon className='famenu' icon={faTabletScreenButton} />
            </div>
            
            </div>
            <div className='scrapLabel'><p>Próba</p></div>
          </div>
        
          </div>
      <hr></hr>
      <div className='statRow' style={{ borderColor: primaryColor}}>
      <div className='paymentDiv'>
                  <p className='paymentQty'>{getCleanCard("7000")} db<br></br>eladott</p>
                  <p className='productType pay' style={{ backgroundColor: primaryColor}}>7 000 Ft</p>
                  <br></br>
                  <p className='productType'>{(getCleanCard("7000")*7000).toLocaleString()} Ft</p>
                  <br></br>
                  <FontAwesomeIcon className='famenu' icon={faHandSparkles} />
                </div>
                <div className='paymentDiv'>
                  <p className='paymentQty'>{getCleanCard("14000")} db<br></br>eladott</p>
                  <p className='productType pay' style={{ backgroundColor: primaryColor}}>14 000 Ft</p>
                  <br></br>
                  <p className='productType' >{(getCleanCard("14000")*14000).toLocaleString()} Ft</p>
                  <br></br>
                  <FontAwesomeIcon className='famenu' icon={faHandSparkles} />
                </div>
                <div className='paymentDiv'>
                  <p className='paymentQty'>{getCleanCard("6000")} db<br></br>eladott</p>
                  <p className='productType pay' style={{ backgroundColor: primaryColor}}>6 000 Ft</p>
                  <br></br>
                  <p className='productType'>{(getCleanCard("6000")*6000).toLocaleString()} Ft</p>
                  <br></br>
                  <FontAwesomeIcon className='famenu' icon={faHandSparkles} />
                </div>
                <div className='paymentDiv'>
                  <p className='paymentQty'>{getCleanCard("12000")} db<br></br>eladott</p>
                  <p className='productType pay' style={{ backgroundColor: primaryColor}}>12 000 Ft</p>
                  <br></br>
                  <p className='productType' >{(getCleanCard("12000")*12000).toLocaleString()} Ft</p>
                  <br></br>
                  <FontAwesomeIcon className='famenu' icon={faHandSparkles} />
                </div>
                <div className='paymentDiv'>
                  <p className='paymentQty'>{getUsedCleanCard("6000")} db beváltott</p>
                  <p className='productType pay' style={{ backgroundColor: primaryColor}}>6 000 Ft</p>
                  <br></br>
                  <FontAwesomeIcon className='famenu' icon={faHandSparkles} />
                </div>
                <div className='paymentDiv'>
                  <p className='paymentQty'>{getUsedCleanCard("12000")} db beváltott</p>
                  <p className='productType pay' style={{ backgroundColor: primaryColor}}>12 000 Ft</p>
                  <br></br>
                  <FontAwesomeIcon className='famenu' icon={faHandSparkles} />
                </div>
                <div className='paymentDiv'>
                  <p className='paymentQty'>{getUsedCleanCard("7000")} db beváltott</p>
                  <p className='productType pay' style={{ backgroundColor: primaryColor}}>7 000 Ft</p>
                  <br></br>
                  <FontAwesomeIcon className='famenu' icon={faHandSparkles} />
                </div>
                <div className='paymentDiv'>
                  <p className='paymentQty'>{getUsedCleanCard("14000")} db beváltott</p>
                  <p className='productType pay' style={{ backgroundColor: primaryColor}}>14 000 Ft</p>
                  <br></br>
                  <FontAwesomeIcon className='famenu' icon={faHandSparkles} />
                </div>
            </div>
            <div className='scrapLabel'><p>Tisztítás kártya</p></div>
          
    </div>
  );

}